import {
  renderChartToScreen,
  getChartColor,
  getBorderColor,
} from "../util/charts";
import { API_URL, API_KEY, generateIntervals, sortByDate, formatDate } from "../util";

document.addEventListener("turbolinks:load", function() {
  // if about-page
  if (document.getElementById("about-page")) {
    // pricesChart();
    // maybeSurveyTimestamps();
    makeListsAndSurveyCharts();
  }
});

async function makeListsAndSurveyCharts() {
  setInterval(function() {
    if (
      (new Date().getMinutes() % 5 === 0) &&
      (new Date().getHours() < 20 && new Date().getHours() >= 11)
    ) {
      makeChart()
    }
  }, 1000 * 60)

  const [listRequestRes, surveyRes, adjustmentsRes] = await Promise.all([
    await (
      await fetch(`${API_URL}/analytics/list-requests?days=1`, {
        headers: { authorization: `Bearer ${API_KEY}` },
      })
    ).json(),
    await (
      await fetch(`${API_URL}/surveys/?days=1`, {
        headers: { authorization: `Bearer ${API_KEY}` },
      })
    ).json(),
    await (
      await fetch(`${API_URL}/shows/adjustments?days=1`, {
        headers: { authorization: `Bearer ${API_KEY}` },
      })
    ).json()
  ])

  const listRequests = listRequestRes.data
  const { surveys } = surveyRes.data
  const adjustments = adjustmentsRes.data

  const startTime = new Date();
  startTime.setHours(11, 0, 0, 0); // 11am EST

  let title = "Right Now at TKTS"
  const now = new Date();
  const endTime = new Date();
  if (now.getHours() > 20) { // 8pm EST
    endTime.setHours(20, 0, 0, 0); // 8pm EST
    title = `${new Date().toLocaleString(window.navigator.language, { dateStyle: "full" })} at TKTS`
  } else {
    endTime.setTime(now.getTime());
  }
  const intervals = generateIntervals(startTime, endTime);

  const empty = intervals.reduce(function(acc, interval) {
    acc[interval.getTime()] = []
    return acc
  }, {})
  const intervaledListRequests = listRequests.reduce(function(acc, lr) {
    const date = new Date(lr * 1000)

    intervals.forEach(interval => {
      if (date >= interval && date < new Date(interval.getTime() + 5 * 60 * 1000)) {
        acc[interval.getTime()].push(lr);
      }
    });

    return acc
  }, JSON.parse(JSON.stringify(empty)))
  const intervaledSurveys = surveys.reduce(function(acc, survey) {
    const date = new Date(survey.timestamp)

    intervals.forEach(interval => {
      if (date >= interval && date < new Date(interval.getTime() + 5 * 60 * 1000)) {
        acc[interval.getTime()].push(survey);
      }
    });

    return acc
  }, JSON.parse(JSON.stringify(empty)))
  const intervaledAdjustments = adjustments.reduce(function(acc, adjustment) {
    const date = new Date(adjustment * 1000)

    intervals.forEach(interval => {
      if (date >= interval && date < new Date(interval.getTime() + 5 * 60 * 1000)) {
        acc[interval.getTime()].push(adjustment);
      }
    });

    return acc
  }, JSON.parse(JSON.stringify(empty)))

  let totalListRequests = 0;
  let totalSurveys = 0;
  let totalAdjustments = 0;

  const configTop = {
    type: "line",
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            // color: "#f0f0f0",
            font: { family: "monospace" }
          },
        },
        x: {
          ticks: {
            font: { family: "monospace" }
          }
        }
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: { family: "monospace" }
          }
        },
        tooltip: {
          font: { family: "monospace" },
        },
        title: {
          font: { family: "monospace" },
          display: true,
          text: title,
          position: "top",
        },
      },
    },
    data: {
      labels: Object.keys(intervaledSurveys).map(d => new Date(parseInt(d)).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })),
      datasets: [
        {
          label: "List Requests",
          data: Object.values(intervaledListRequests).map(function(list) {
            totalListRequests += list.length
            return totalListRequests
          }),
          backgroundColor: getChartColor(0),
          borderColor: getChartColor(0),
          pointRadius: 0,
          borderWidth: 2,
        },
        {
          label: "Survey Responses",
          data: Object.values(intervaledSurveys).map(function(list) {
            totalSurveys += list.length
            return totalSurveys
          }),
          backgroundColor: getChartColor(1),
          borderColor: getChartColor(1),
          pointRadius: 0,
          borderWidth: 2,
        },
      ],
    }
  }

  renderChartToScreen("chart--top", configTop)

  const configBottom = {
    type: "line",
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            // color: "#f0f0f0",
            font: { family: "monospace" }
          },
        },
        x: {
          ticks: {
            font: { family: "monospace" }
          }
        }
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            font: { family: "monospace" }
          }
        },
        tooltip: {
          font: { family: "monospace" },
        },
        title: {
          font: { family: "monospace" },
          display: true,
          text: title,
          position: "top",
        },
      },
    },
    data: {
      labels: Object.keys(intervaledSurveys).map(d => new Date(parseInt(d)).toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
      })),
      datasets: [
        {
          label: "Intra-Day Listing Additions + Adjustments to Price",
          data: Object.values(intervaledAdjustments).map(function(list) {
            totalAdjustments += list.length
            return totalAdjustments
          }),
          backgroundColor: getChartColor(0),
          borderColor: getChartColor(0),
          pointRadius: 0,
          borderWidth: 2,
          stepped: true
        }
      ],
    }
  }

  renderChartToScreen("chart--bottom", configBottom)
}

const maybeSurveyTimestamps = () => {
  if (document.getElementById("about-page")) {
    surveyTimestamps();
  }
  setTimeout(() => {
    maybeSurveyTimestamps();
  }, 1000 * 20);
};

const surveyTimestamps = async () => {
  const url = new URL(`${API_URL}/surveys/demo-timestamps`);
  url.searchParams.set("limit", 11);
  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const timestamps = result.data.surveys;

  const demoList = document.querySelector(".demographics__list");
  demoList.innerHTML = "";

  timestamps.forEach((item, i) => {
    const p = document.createElement("p");
    if (!i) p.classList.add("blink");
    p.classList.add("demographics__list-item");

    const img = document.createElement("img");
    img.src = `https://flagcdn.com/w20/${item.country.toLowerCase()}.png`;
    p.appendChild(img);

    const spacer = document.createElement("span");
    spacer.classList.add("demographics__list-item--spacer");
    p.appendChild(spacer);

    // The parsing of this string assumes it comes back in eastern
    const [datePiece, time, _adj, _tz] = item.timestamp.split(" ");
    const [yPiece, mPiece, dPiece] = datePiece.split("-");
    // const safariFormattedDatePiece = [mPiece, dPiece, yPiece].join("/");
    // const safariFormattedDate = `${safariFormattedDatePiece} ${time}`;

    const dateString = [mPiece, dPiece, yPiece].join("/");

    // const date = new Date(safariFormattedDate);
    // let m = (date.getMonth() + 1).toString();
    // if (m.length === 1) m = `0${m}`;
    // let d = date.getDate().toString();
    // if (d.length === 1) d = `0${d}`;
    // const y = date.getFullYear().toString().slice(2);
    let ampm = "AM";
    // let hour = date.getHours();
    let [hour, min] = time.split(":").map(min => parseInt(min))
    if (!hour) hour = 12;
    if (hour >= 12) ampm = "PM";
    if (hour > 12) hour -= 12;
    hour = hour.toString();
    if (hour.length === 1) hour = `0${hour}`;
    // let min = date.getMinutes().toString();
    min = min.toString();
    if (min.length === 1) min = `0${min}`;
    // const dateString = `${m}/${d}/${y}`;

    const dateSpan = document.createElement("span");
    dateSpan.innerText = `Survey: ${dateString}`;
    p.appendChild(dateSpan);

    const otherSpacer = document.createElement("span");
    otherSpacer.classList.add("demographics__list-item--spacer");
    p.appendChild(otherSpacer);

    const timeSpan = document.createElement("span");
    timeSpan.innerText = `${hour}:${min} ${ampm}`;
    p.appendChild(timeSpan);

    demoList.appendChild(p);
  });
};

const pricesChart = async () => {
  const url = new URL(`${API_URL}/shows/tallies/price-ranges`);
  url.searchParams.set("days", 4);

  const result = await fetch(url, {
    headers: { authorization: `Bearer ${API_KEY}` },
  }).then((res) => res.json());

  const priceRanges = result.data.priceRanges;

  const sortedDates = sortByDate(Object.keys(priceRanges)).filter(
    (_, i) => i > Object.keys(priceRanges).length - 4
  );

  const labels = sortedDates.map((date) =>
    formatDate(date.split("-").join("/"))
  );
  const data = {
    labels,
    datasets: [
      {
        type: "bar",
        label: "Range",
        data: sortedDates.map((date) => {
          const info = priceRanges[date]["all"];
          return [info?.low ?? 0, info?.high ?? 0];
        }),
        backgroundColor: getChartColor(0),
        borderColor: getChartColor(0),
        borderWidth: 1,
      },
      // Averages
      {
        type: "line",
        label: "Average",
        data: sortedDates.map((date) => {
          const info = priceRanges[date]["all"];
          return info?.averageListed || null;
        }),
        backgroundColor: getBorderColor(0),
        borderColor: getBorderColor(0),
        borderWidth: 1,
      },
    ],
  };
  const config = {
    type: "bar",
    data,
    options: {
      tension: 0.3,
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: "top",
          labels: {
            font: {
              family: "monospace",
            },
          },
        },
        title: {
          display: false,
          text: "Broadway Range and Average at TKTS",
          position: "top",
        },
        tooltip: {
          callbacks: {
            title: ([context]) => {
              const date = sortedDates[context.dataIndex];
              return formatDate(date.split("-").join("/"));
            },
            label: (context) => {
              if (Array.isArray(context.raw)) {
                const [low, high] = context.raw;
                return `$${parseFloat(low).toFixed(2)} - ${parseFloat(
                  high
                ).toFixed(2)}`;
              }

              return `$${parseFloat(context.formattedValue).toFixed(2)}`;
            },
          },
        },
      },
      scales: {
        y: { ticks: { font: { family: "monospace" } } },
        x: {
          ticks: {
            font: {
              family: "monospace",
              weight: labels.map((label) =>
                [0, 6].includes(new Date(label).getDay()) ? "bold" : "normal"
              ),
            },
          },
        },
      },
    },
  };

  renderChartToScreen("price-range-chart", config);
};
